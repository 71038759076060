<template>
  <div v-if="studentIdCardsLink" class="v3-dashboard-banners-item">
    <div class="text-center">
      <div>
        <h2 class="v3-dashboard-banners-item-title">
          <i class="ri-file-download-line"></i> Student ID cards
        </h2>
      </div>
      <Transition name="banner">
        <div v-if="active" class="mt-2">
          Your request for the Student ID cards is generated and ready for
          download.
          <BaseButton rounded solid @click="openFile" class="btn p-2 ms-2">
            Download
          </BaseButton>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted } from "vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton"

export default {
  name: "DownloadStudentIDCardsBanner",
  components: {
    BaseButton
  },
  props: {
    studentIdCardsLink: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()

    const user = computed(() => store.getters["authentication/user"])

    const getAllStudentIDCards = () => {
      store.dispatch("users/getAllStudentIDCardsPDF")
    }

    const openFile = () => {
      window.open(props.studentIdCardsLink, "_blank")
    }
    onMounted(() => {
      if (user.value.role === "admin") {
        getAllStudentIDCards()
      }
    })
    return {
      openFile
    }
  }
}
</script>
