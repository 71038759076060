<template>
  <div v-if="comments">
    <div class="v3-comments">
      <div
        v-for="(comment, index) in comments"
        :key="index"
        class="v3-comments-comments"
      >
        <span class="bold-comment">
          <span v-if="comment.user && comment.user !== 0">{{
            comment.user.first_name + " " + comment.user.last_name
          }}</span>
          <span v-else>System</span
          ><span
            >{{
              " [" +
              helpersJS.transformDate(comment.created_at, "M/D/yyyy h:mm A") +
              "]"
            }}
            :</span
          >
        </span>
        {{ comment.comment }}
      </div>
      <div v-if="comments.length == 0">No comments</div>
      <Loader :is-processing="state.isLoading" classes="position-absolute" />
    </div>
    <div class="mt-3">
      <div class="">
        <InputField
          v-model="state.form.comment"
          class="w-full"
          type="textarea"
          :rows="2"
          max-length="250"
          max-chars="250"
          placeholder="Type your comment here ..."
          @blur="resumeFocus"
          @input="typing()"
          :invalid-feedback="errors.comment"
        />
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton @click="onCancel" rounded>Cancel</LoadingButton>
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="createComment(state.form.id)"
          class="mt-4 px-4 mx-2"
          solid
          rounded
          >Comment</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import helpersJS from "@/helpers/index"
import Loader from "@/v3components/shared/Loader/Loader"
import InputField from "@/v3components/shared/Form/InputField"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"

export default {
  name: "CommentsComponent",
  components: {
    Loader,
    InputField,
    InfoBox,
    LoadingButton
  },
  props: {
    toID: {
      type: Number,
      required: true
    },
    commentableType: {
      type: String,
      required: true
    },
    silent: {
      type: Boolean,
      default: false
    }
  },
  emits: ["commentCreated", "cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isLoading: false,
      form: {
        comment: null,
        id: null
      },
      serverErrors: {},
      serverRes: null
    })

    const comments = computed(() => store.getters["comments/comments"])

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      form: {
        comment: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      if (!props.silent) {
        getPassComments()
      }
    })

    const typing = () => {
      if (state.form.comment !== null) {
        setResponseInfoBox()
      }
    }

    const resumeFocus = (e) => {
      setTimeout(() => {
        if (state.form.comment) {
          e.target.focus()
        }
      }, 100)
    }

    const getPassComments = () => {
      state.isLoading = true
      state.form.id = props.toID
      const getCommentData = {
        passId: props.toID,
        commentableType: props.commentableType
      }
      store.dispatch("comments/getPassComments", getCommentData).finally(() => {
        state.isLoading = false
      })
    }

    const createComment = (id) => {
      if (isValid.value) {
        const comment = {
          toID: id,
          data: {
            comment: state.form.comment,
            commentable_type: props.commentableType,
            permission: false
          }
        }
        submit(comment)
      } else {
        v$.value.$touch()
      }
    }

    const submit = (data) => {
      state.isLoading = true
      store
        .dispatch("comments/createComment", data)
        .then((response) => {
          const dataResponse = response.data.data
          if (dataResponse) {
            setResponseInfoBox("Success", "Successfully created!")
            state.isLoading = false
            resetFormData()
            const commentData = {
              id: data.toID,
              comment: data.data.comment
            }
            if (props.commentableType == "App\\Models\\Pass") {
              store.commit("passes/UPDATE_PASS_HISTORY_COMMENT", commentData)
            }
            if (props.commentableType == "App\\Models\\AppointmentPass") {
              store.commit("adultAptPass/SET_COMMENTABLE_PASS", commentData)
            }
            setTimeout(() => {
              onCancel()
            }, 1800)
          }
        })
        .catch((err) => {
          state.isLoading = false
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      setResponseInfoBox()
      emit("cancel")
    }

    const resetFormData = () => {
      state.form = {
        comment: null,
        id: null
      }
    }

    return {
      state,
      helpersJS,
      comments,
      errors,
      isValid,
      typing,
      resumeFocus,
      createComment,
      setResponseInfoBox,
      onCancel
    }
  }
}
</script>
