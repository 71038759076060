<template>
  <div v-if="studentIdCardsLink" class="unavailable-alert">
    <div class="content">
      Your request for the Student ID cards is generated and ready for download.
      <button
        @click="openFile"
        class="btn p-2 bg-gradient-blue text-white ms-2"
      >
        Download
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadStudentIDCardsBanner",
  props: {
    studentIdCardsLink: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const openFile = () => {
      window.open(props.studentIdCardsLink, "_blank")
    }

    return {
      openFile
    }
  }
}
</script>
