<template>
  <div>
    <div class="stat limit" @click="state.isModalVisible = true">
      Building Passes
      <span class="d-flex flex-column cursor-pointer">
        {{ allActivePassesLength }}
        / {{ totalPassesLength }}
        <b class="limit-count">Limit - {{ activePassLimit }}</b>
      </span>
    </div>
    <CModal
      backdrop="static"
      :visible="state.isModalVisible"
      :unmount-on-close="false"
      :close-on-backdrop="false"
    >
      <div
        class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
      >
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click="state.isModalVisible = false"
        >
          <span class="close-icon"></span>
        </div>
        <HelpCenterButton
          content-key="limit_acive_passes_pop_up"
          :is-old="true"
        />
      </div>
      <div class="px-3">
        <h2 class="mb-4 text-center">Limit Active Passes</h2>
        <LimitActivePassForm
          ref="CreateLimitActivePassForm"
          @close-modal="state.isModalVisible = false"
        />
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { reactive, computed } from "vue"
import { useStore } from "vuex"
import LimitActivePassForm from "@/v3components/Forms/LimitActivePassForm"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"

export default {
  name: "BuildingPassesOld",
  components: {
    LimitActivePassForm,
    HelpCenterButton
  },
  setup() {
    const store = useStore()

    const state = reactive({
      isModalVisible: false
    })

    const activePassLimits = computed(() => {
      return store.getters["activePass/activePassLimits"]
    })

    const totalPassesLength = computed(() => {
      return store.getters["dashboardCounters/totalPassesLength"]
    })

    const allActivePassesLength = computed(() => {
      return store.getters["dashboardCounters/allActivePassesLength"]
    })

    const activePassLimit = computed(() => {
      return activePassLimits.value !== null &&
        activePassLimits.value.limit !== "-"
        ? activePassLimits.value.limit
        : ""
    })

    return {
      state,
      activePassLimits,
      totalPassesLength,
      allActivePassesLength,
      activePassLimit
    }
  }
}
</script>
