<template>
  <div
    v-if="unavailable || isUserUnavailable"
    class="v3-dashboard-banners-item"
  >
    <div class="text-center">
      <div>
        <h2 class="v3-dashboard-banners-item-title">
          <i class="ri-briefcase-line"></i> Out of Office
          <span class="cursor-pointer ms-1" @click="closeUnavailableAlert()"
            ><i class="ri-close-line"></i
          ></span>
        </h2>
      </div>
      <Transition name="banner">
        <div v-if="active" class="mt-2">
          <div>
            Your Out of Office is currently on and will end on
            {{
              helpers.transformDate(
                unavailable.to_date,
                "ddd, MM/DD/YYYY h:mm A"
              )
            }}
          </div>
          <div
            v-if="unavailable.comment"
            class="v3-dashboard-banners-item-reason"
          >
            Note: {{ unavailable.comment }}
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import helpers from "@/helpers/index"

export default {
  name: "OutOfOfficeBanner",
  props: {
    unavailable: {
      type: Object,
      default: null
    },
    isUserUnavailable: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()

    const closeUnavailableAlert = () => {
      if (
        window.confirm("Are you sure you want to delete this Out Of Office?")
      ) {
        endUnavailable(props.unavailable)
      }
    }

    const endUnavailable = (unavailable) => {
      if (unavailable && unavailable.id && unavailable.status) {
        store.dispatch("outOfOfficePasses/updateOutOfOfficeStatus", {
          id: unavailable.id,
          params: { dashboard: true }
        })
      }
    }

    return {
      helpers,
      closeUnavailableAlert
    }
  }
}
</script>
