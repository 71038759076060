<template>
  <div
    v-if="
      (itemsBlocked && itemsBlocked.items && itemsBlocked.items.length !== 0) ||
      isUserUnavailable ||
      unavailable ||
      showExceededIncidentsBanner ||
      studentIdCardsLink
    "
    class="container mt-4"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex flex-column justify-conten-center position-relative">
          <div class="v3-dashboard-banners">
            <!-- PASS BLOCK banner-->
            <PassBlockBanner :pass-block="itemsBlocked" :active="show" />

            <!-- OUT OF OFFICE banner -->
            <OutOfOfficeBanner
              :unavailable="unavailable"
              :user-unavailable="isUserUnavailable"
              :active="show"
            />

            <!-- TARDY CONSEQUENCES banner -->
            <ConsequencesBanner
              :exceeded-incidents="showExceededIncidentsBanner"
              :active="show"
            />

            <!-- DOWNLOAD ID CARD banner -->
            <DownloadStudentIDCardsBanner
              :student-id-cards-link="studentIdCardsLink"
              :active="show"
            />
          </div>
          <RowToggler
            :classes="'bottom-minus-17'"
            :active="show"
            @click="toggleSidebar()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import PassBlockBanner from "@/v3components/Dashboard/PassBlockBanner"
import OutOfOfficeBanner from "@/v3components/Dashboard/OutOfOfficeBanner"
import ConsequencesBanner from "@/v3components/Dashboard/ConsequencesBanner"
import DownloadStudentIDCardsBanner from "@/v3components/Dashboard/DownloadStudentIDCardsBanner"
import RowToggler from "@/v3components/Dashboard/RowToggler"

export default {
  name: "Banners",
  components: {
    PassBlockBanner,
    OutOfOfficeBanner,
    ConsequencesBanner,
    DownloadStudentIDCardsBanner,
    RowToggler
  },
  setup() {
    const store = useStore()

    const passBlock = computed(() => {
      return store.getters["dashboardTable/passBlock"]
    })

    const itemsBlocked = computed(() => {
      return passBlock.value ? passBlock.value : {}
    })

    const unavailable = computed(() => {
      return store.getters["dashboardTable/unavailable"]
    })

    const isUserUnavailable = computed(() => {
      return store.getters["authentication/isUserUnavailable"]
    })

    const exceededIncidents = computed(() => {
      return store.getters["exceededIncidents/exceededIncidents"]
    })

    const userRole = computed(() => {
      return store.getters["authentication/getUserRole"]
    })

    const showExceededIncidentsBanner = computed(() => {
      return exceededIncidents.value && userRole.value === "admin"
    })

    const studentIdCardsLink = computed(() => {
      return store.getters["users/studentIdCardsLink"]
    })

    const show = computed(() => {
      return store.state.layout.bannersShow
    })

    const toggleSidebar = () => {
      store.commit("layout/set", ["bannersShow", !show.value])
    }

    return {
      itemsBlocked,
      unavailable,
      isUserUnavailable,
      showExceededIncidentsBanner,
      studentIdCardsLink,
      show,
      toggleSidebar
    }
  }
}
</script>
