<template>
  <CContainer :fluid="true">
    <CRow>
      <!-- MODALS -->
      <CModal
        backdrop="static"
        :visible="state.modal.isVisible"
        :close-on-backdrop="false"
      >
        <div
          class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
        >
          <div
            class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
            @click="closeModal()"
          >
            <span class="close-icon"></span>
          </div>
          <HelpCenterButton
            content-key="limit_location_max_cap"
            :is-old="true"
          />
        </div>
        <div v-if="state.activeRoom" class="px-3">
          <h2 class="mb-4 text-center">Limit Location Max Cap</h2>
          <CreateLimitLocationMaxCapForm
            :is-in-view="false"
            :is-dashboard-view="true"
            :active-room="
              state.activeRoom.room_advance
                ? state.activeRoom.room_advance
                : state.activeRoom
            "
            :editable-limit-data="
              state.activeRoom.room_advance
                ? state.activeRoom.room_advance
                : state.activeRoom
            "
            @updated-limit-location-max-cap="state.modal.isVisible = false"
            @close-modal="state.modal.isVisible = false"
          />
        </div>
        <template #footer-wrapper>
          <div></div>
        </template>
      </CModal>
      <CCol
        v-if="autoPassesRooms && autoPassesRooms.length"
        md="12"
        class="bg-white d-flex justify-content-center flex-wrap"
      >
        <div
          :class="state.hideRoomCapacities ? 'd-none' : 'd-flex'"
          class="rooms-stats-container align-items-center px-4"
        >
          <div class="me-4 stats-title">Room Capacities:</div>
          <div class="rooms-stats">
            <div
              v-for="(room, index) in autoPassesRooms"
              :key="index"
              class="stat cursor-pointer"
              @click="showModal(room)"
            >
              {{ room.room.name }}
              <span>
                {{ room.limit !== null ? room.limit : "-" }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="text-text-color-dark p-2 w-full text-center cursor-pointer"
          @click="state.hideRoomCapacities = !state.hideRoomCapacities"
        >
          <div
            v-if="state.hideRoomCapacities"
            class="d-flex align-items-center justify-content-center toggler-btn"
          >
            <span class="stats-title">Room Capacities</span>
            <span class="flaticon-down-arrow ms-1"></span>
          </div>
          <div v-else class="d-flex align-items-center justify-content-center">
            <span class="flaticon-up-arrow ms-1"></span>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { reactive, computed } from "vue"
import { useStore } from "vuex"
import CreateLimitLocationMaxCapForm from "@/v3components/Forms/CreateLimitLocationMaxCapForm"
import HelpCenterButton from "@/v3components/HelpCenterButton"

export default {
  name: "RoomStatsOld",
  components: {
    CreateLimitLocationMaxCapForm,
    HelpCenterButton
  },
  setup() {
    const store = useStore()

    const state = reactive({
      modal: {
        isVisible: false
      },
      activeRoom: null,
      hideRoomCapacities: true
    })

    const autoPassesRooms = computed(() => {
      return store.getters["dashboardTable/staffSchedules"]
    })

    const showModal = (room) => {
      state.activeRoom = room
      state.modal.isVisible = true
    }

    const closeModal = () => {
      state.modal.isVisible = false
      state.activeRoom = null
    }

    return {
      state,
      autoPassesRooms,
      showModal,
      closeModal
    }
  }
}
</script>
